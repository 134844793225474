import React from 'react'
import { AiFillHome } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { FaShoppingCart } from "react-icons/fa";
import { BiSolidFoodMenu } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export const Nav_foot = () => {
  const { loginData } = useSelector((state) => state.user);
  const navigate=useNavigate();
  return (
      <div class="bottom-nav">
            <div class="nav-item" onClick={()=>{navigate("/")}}><AiFillHome/>Home</div>
            <div class="nav-item" onClick={()=>{navigate("/")}}><BiSolidFoodMenu />Menu</div>
            <div class="nav-item" onClick={()=>{navigate("/cart")}}><FaShoppingCart />Cart</div>
            <div class="nav-item" onClick={()=>{loginData ? navigate("/profile"): navigate("/login")}}><CgProfile />Profile</div>
      </div>
  )
}
